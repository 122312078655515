<template>
    <el-dialog
        title="在线仓库商品"
        :visible.sync="visible"
        width="500px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        @close="close"
    >
        <div>
            <table-height-auto ble-height-auto>
                <template v-slot:table="row">
                    <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                        <el-table-column prop="seedName" label="商品名称" >
                            <template #default="scope">
                                <span v-if="scope.row.seedName">{{ scope.row.seedName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="count" label="剩余库存" >
                            <template #default="scope">
                                <span v-if="scope.row.count">{{ scope.row.count }}KG</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>

                <template slot="after">
                    <div class="paging-row">
                        <el-pagination
                            v-if="total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </template>
            </table-height-auto>
        </div>
    </el-dialog>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
  name: '',
  props: {
		dialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		}
	},
    data() {
        return {
            visible: false,
            loading:false,
            index: null,

            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
        }
    },
    methods: {
        getList() {
            this.axios.get(`${URL.farm.farmlandrentgetOnlineStoreBalanceList}/${this.currentRow.id}`).then(res=>{
                if(res.code === '0'){
                    this.tableData= res.data.records
                    this.total = res.data.total;
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

        close() {
            this.$emit('closeCheck')
        },

        handleSizeChange(val,type){
            this.pageSize = val;
            this.currentPage = 1;
            this.getList()
        },

        handleCurrentChange(val,type) {
            this.currentPage = val;
            this.getList();
        },

    },
    watch:{
        currentRow: {
            handler: function(oldV, newV) {
                console.log(newV);
            },
        },
        dialogVisible: function(val) {
            this.visible = val;
            this.getList();
        }

    },

    mounted() {

    }
}
</script>

<style lang="less" scoped>
</style>
