<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="用户账号">
                        <el-input v-model="search.phone" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="土地名称">
                        <el-input v-model="search.landName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="土地编码">
                        <el-input v-model="search.landCode" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="土地编号">
                        <el-input v-model="search.landDistrictNiceName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式">
                        <el-input v-model="search.mobile" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="getList">查询</el-button>
                        <el-button type="primary" size="medium" @click="reset">重置</el-button>
                        <!-- <el-button size="mini"  type="primary" @click="edit({})">修改种子状态</el-button> -->
                        <!-- <el-button size="mini"  type="primary" @click="check({})">查看</el-button> -->
                         <!-- <el-button size="mini"  type="primary" @click="onlineCheck({})">在线任务数</el-button> -->
                    </el-form-item>

                </el-form>
            </template>

            <template v-slot:table="row">
                <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column prop="date" label="用户账号" width="180" align="center">
                        <template #default="scope">
                            <el-image v-if="scope.row && scope.row.pictures && scope.row.pictures.pics" class="avatar" fit="contain" :src="scope.row.pictures.pics[0]" style="width: 30px;height: 30px;Object-fit: cover;boder-radius: 50%;" />
                            <el-image v-else class="avatar" fit="contain" src="http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png" style="width: 30px;height: 30px;Object-fit: cover;boder-radius: 50%;" />
                            {{ scope.row.czlPhone }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickName" label="土地名称" width="120" />
                    <el-table-column prop="landCode" label="土地编码" show-overflow-tooltip width="120" />
                    <el-table-column prop="landDistrictNiceName" label="土地编号" width="120" />
                    <el-table-column prop="landArea" label="租赁土地面积" width="120">
                        <template #default="scope">
                           <span v-if="scope.row.landArea">{{ scope.row.landArea }}m²</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="联系方式" width="120" />

                    <el-table-column prop="status" label="租赁套餐" width="120">
                        <template #default="scope">
                           <span>{{scope.row.packageInfo.packages | packages}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="contactPhone" label="套餐服务" width="120">
                        <template #default="scope">
                           <span @click="check(scope.row)" style="cursor: pointer;">查看</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="onlineTask" label="在线任务数" width="120">
                        <template #default="scope">
                           <span style="color: blue;cursor: pointer;" @click="onlineCheck(scope.row)"> {{ scope.row.onlineTask }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineStoreGoods" label="在线仓库商品"  width="120">
                      <template #default="scope">
                            <span style="color: blue;cursor: pointer;" @click="onlineGoods(scope.row)">{{ scope.row.onlineStoreGoods }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rentStatus" label="租赁状态">
                        <template #default="scope">
                            {{ scope.row.rentStatus == 0 ? '已过期': scope.row.rentStatus == 1 ? '生效中':'' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="rentStartTime" label="授权开始日期"  width="120"/>
                    <el-table-column prop="rentEndTime" label="授权结束日期"  width="120"/>
                    <el-table-column prop="date" label="操作" width="160" align="center" fixed="right">
                        <template slot-scope="scope">
                            <el-button size="mini"  type="primary" @click="edit(scope.row, '编辑')">修改种子状态</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible" width="800px">
            <el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image v-if="showData.type == 1" :src="showData.img" fit="contain" style="width: 760px;height: 430px;"  />
                <video  v-else-if="showData.type == 2" :src="showData.video" controls="controls" style="width: 760px;height: 430px;" >
                    您的浏览器不支持视频播放
                </video>
                <el-empty v-else :image-size="180" description="数据格式有误！" />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false">确定</el-button>
            </template>
        </el-dialog>
        <editSeed @closeAdd="closeAdd" :dialogVisible="dialogVisible" :currentRow="currentRow" />
        <packageService @closeCheck="closeCheck" :dialogVisible="checkDialogVisible" :currentRow="currentRow" />
        <sellGoods @closeCheck="closeCheck" :dialogVisible="onlineDialogVisible" :currentRow="currentRow" />
    </div>
</template>

<script>
import editSeed from "./components/editSeed.vue";
import packageService from "./components/packageService.vue";
import sellGoods from "./components/sellGoods.vue";
import {URL} from '../../../config'

export default {
    name: 'landConfig',
    components: {
        editSeed,
        packageService,
        sellGoods
    },
    filters: {
        packages(data) {
            let packList = []
            data.forEach(e => {
                packList.push(e.name)
            });
            return packList.join(',');
        }
    },
    data() {
        return {
            search: {
                mobile: '',
                landCode: '',
                landDistrictNiceName: '',
                phone: '',
                landName: '',
            },
            loading: false,
            isVisible: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            dialogVisible: false,
            currentRow: {},
            checkDialogVisible: false,
            onlineDialogVisible: false,
        }
    },
    methods: {

        getList() {
            this.axios.post(URL.farm.farmLandrentPage, { pageNo: this.currentPage, pageSize: this.pageSize, ...this.search }).then(res=>{
                if(res.code === '0'){
                    this.tableData = res.data.records
                    this.total = res.data.total;
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

        edit(row) {
            this.dialogVisible = true;
            this.currentRow = row;
        },

        closeAdd() {
            this.dialogVisible = false;
            this.getList();
        },

        check (row) {
            this.checkDialogVisible = true;
            this.currentRow = row;
        },

        closeCheck() {
            this.checkDialogVisible = false;
            this.onlineDialogVisible = false;
            this.getList()
        },

        onlineCheck(row) {
            this.$router.push({ path: '/taskManage', query: {}})
        },

        onlineGoods(row) {
            this.onlineDialogVisible = true;
            this.currentRow = row;
        },

        remove(row) {
            this.$confirm('是否切换状态为上架？', '上下架提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        del() {
            this.$confirm('删除后对应前端共享农场不可查看该土地，是否确认删除？', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        reset () {
            this.search = {
                mobile: '',
                landCode: '',
                landDistrictNiceName: '',
                phone: '',
                landName: '',
            }
            this.getList()
        },

        handleSizeChange(val,type){
            this.pageSize = val;
            this.currentPage = 1;
            this.getList()
        },

        handleCurrentChange(val,type) {
            this.currentPage = val;
            this.getList();
        },
    },
    mounted() {
        this.getList();
    },

}
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
