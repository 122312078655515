<template>
    <el-dialog
        title="修改种子状态"
        :visible.sync="visible"
        width="850px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        @close="close"
    >
        <div>
            <span>我的土地</span><span>共{{ tableData.length }}块地,每块地{{ plantLandArea }}m²</span>
        </div>
        <div class="content">
            <div class="left">
                <div v-for="(item, i) in tableData" :key="i" :class="['item', {'active': item.selected, 'noClick': item.disabled }]" @click="select(item, i)">
                    <div>
                        <el-image style="width:69px;height:59px;" fit="cover" :src="item.seedPic" v-if="item.seedPic" />
                        <el-icon class="el-icon-question" style="width:69px;height:45px;font-size: 30px;" v-else />
                        <!-- <img src="../../../../components/public/query.svg" style="width:59px;heigth=59px;" /> -->
                    </div>
                    <div>
                        <span v-if="true">{{ item.status == 0 ?'待播种': item.status == 1 ? '已播种': item.status == 2 ? '发芽中': item.status == 3 ? '成长中': item.status == 4 ? '可收获':'' }}</span>
                    </div>
                </div>
            </div>
            <div class="right">
                <div>修改种子状态:</div>
                <div>
                    <el-radio-group v-model="radio">
                        <!-- <el-radio :label="0" class="radio-item">待播种</el-radio> -->
                        <el-radio :label="1" class="radio-item">已播种</el-radio>
                        <el-radio :label="2" class="radio-item">发芽中</el-radio>
                        <el-radio :label="3" class="radio-item">成长中</el-radio>
                        <el-radio :label="4" class="radio-item">可收获</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
    name: '',
    props: {
		dialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		}
	},
    data() {
        return {
            visible: false,
            loading:false,
            radio: '',
            index: null,
            tableData: {},
            plantLandArea: ''
        }
    },
    methods: {
        getList() {
            this.axios.get(`${URL.farm.farmLandrentGetById}/${this.currentRow.id}`).then(res=>{
                if(res.code === '0'){
                    this.plantLandArea = res.data.plantLandArea;
                    this.tableData = res.data.plantStatus.status.map(v => {
                        if(v.status == 0 || v.status == 4) {
                            v.disabled = true;
                        } else {
                            v.disabled = false;
                        }
                        v.selected = false;
                        return v;
                    });
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

        select(item, i) {
            if (item.disabled == false) {
                item.selected = !item.selected;
            }
        },

        close() {
            this.radio = ''
            this.$emit('closeAdd')
        },

        submit() {
            this.axios.post(URL.farm.farmLandrentChangeLandPlantStatus, {
                id: this.currentRow.id,
                landNos: this.tableData.filter(({ selected }) => selected).map(({ no }) => no),
                status: this.radio,
            }).then(res=>{
                if(res.code === '0'){
                    this.close()
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

    },
    watch:{
        currentRow: {
            handler: function(oldV, newV) {
                console.log(oldV);
            },
            // deep: true
        },
        dialogVisible: function(val) {
            this.visible = val;
            this.getList();
        }

    },
    mounted() {

    }
}
</script>

<style lang="less" scoped>
.content {
    display: flex;

    .left {
        width: 500px;
        padding: 20px 0;
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;

        .item {
            padding: 10px 20px 5px 20px;
            background: #F5F5F5;
            // background: red;
            margin-right: 10px;
            margin-bottom: 10px;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
        }

        .active {
            background: #07a675;
        }

        .noClick {
            cursor: not-allowed;
        }
    }

    .right {
        .radio-item {
            display: block;
            padding-top: 20px;
        }
    }
}
</style>
